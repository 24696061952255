import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import CtaPrimary from "../../components/CtaPrimary";
import ContactForm from "../../components/ContactForm";
import FeatureBox from "../../components/FeatureBox";
import QuoteBox from "../../components/QuoteBox";
import MainContent from "../../components/MainContent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import Breadcrumb from "../../components/Breadcrumb";
import ProfileCard from "../../components/ProfileCard";
import Img from "../../components/Img.js";
import H from "../../components/Headline";

const breadCrumbLevels = {
    Home: "/",
    "Über mich": "/de/ueber-mich",
    "SEO Freelancer": "/de/seo-freelancer"
};

// Hreflang data
const alternateLangs = [
    {
        hreflang: "en",
        href: "/en/seo-freelancer"
    },
    {
        hreflang: "de",
        href: "/de/seo-freelancer"
    },
    {
        hreflang: "x-default",
        href: "/en/seo-freelancer"
    }
];

// todo
// i have too many entities in there, should only be "freelancer" and "SEO" or so. And not the stuffing.
// const Schema = `
// {
//     "@context": "https://schema.org/",
//     "@graph": [
//         {
//             "@type": "Service",
//             "id": "https://bluerivermountains.com/de/seo-freelancer",
//             "serviceType": "Suchmaschinenoptimierung",
//             "name": "SEO Freelancer",
//             "alternateName": [
//                 "Freiberuflicher SEO",
//                 "Freelancer SEO Berater",
//                 "Freelancer SEO Spezialist",
//                 "Freelancer SEO Expert"
//             ],
//             "description": "Als freiberuflicher Suchmaschinenoptimierer bietet Matthias Kupperschmidt On-Site SEO, Offpage SEO und technische Suchmaschinenoptimierung für bessere Google Rankings. Zusätzlich wird die Planung und Produktion des SEO Copywriting übernommen und alle Texte eingestellt. Die Leistung kann auf Projektbasis für eine begrenzte Anzahl von Wochen Laufen, oder als monatliche SEO Optimierung.",
//             "disambiguatingDescription": "SEO Freiberufler anstatt einer Agentur. Kunden können direkt mit dem Spezialisten zusammenarbeiten und können somit komplizierte Projektmanagement Strukturen einer Agentur umgehen. Dadurch werden Kosten gespart und die Kommunikationswege verkürzt. Matthias Kupperschmidt leistet die selben professionellen Dienstleistungen wie eine SEO Firma.",
//             "image": {
//                 "@type": "ImageObject",
//                 "name": "Blue River Mountains SEO Beratung",
//                 "url": "https://bluerivermountains.com/static/logo-brm-sml-with-background-9f4926b4099a8cfafd9b12eda01a08ab.svg",
//                 "height": "777px",
//                 "width": "777px"
//             },
//             "provider": {
//                 "@type": "Person",
//                 "name": "Matthias Kupperschmidt",
//                 "sameAs": [
//                     "https://www.freelancermap.de/freelancer-verzeichnis/profile/it/256238-profil-matthias-kupperschmidt-suchmaschinenoptimierer-aus-copenhagen.html",
//                     "https://www.freelancermap.de/freelancer-verzeichnis/profile/grafik-content-medien/256238-profil-matthias-kupperschmidt-suchmaschinenoptimierer.html",
//                     "https://t3n.de/pioneers/profile/matthias-kupperschmidt/"
//                 ]
//             },
//             "sameAs": "https://de.wikipedia.org/wiki/Suchmaschinenoptimierung",
//             "termsOfService": "https://bluerivermountains.com/de/allgemeine-geschaeftsbedingungen",
//             "url": "https://bluerivermountains.com/de/seo-freelancer",
//             "slogan": "Mehr Kunden und mehr Umsatz",
//             "brand": {
//                 "@type": "Organization",
//                 "name": "Blue River Mountains",
//                 "logo": "https://bluerivermountains.com/static/logo-brm-sml-with-background-9f4926b4099a8cfafd9b12eda01a08ab.svg",
//                 "sameAs": [
//                     "https://yellow.place/de/blue-river-mountains-copenhagen-denmark",
//                     "https://de.gravatar.com/bluerivermountains",
//                     "https://t3n.de/firmen/bluerivermountains/",
//                     "https://www.provenexpert.com/de-de/bluerivermountains/",
//                     "https://dasauge.de/-bluerivermountains/"
//                 ]
//             },
//             "award": [
//                 "Bester SEO Freelancer 2020",
//                 "Top Freelancer für Suchmaschinenoptimierung in Deutschland 2020",
//                 "Bester SEO Freiberufler 2020"
//             ],
//             "areaServed": {
//                 "@type": "GeoShape",
//                 "name": "DACH Region",
//                 "addressCountry": [
//                     "Deutschland",
//                     "Österreich",
//                     "Schweiz"
//                 ],
//                 "additionalType": "D-A-CH",
//                 "alternateName": "D-A-CH",
//                 "sameAs": "https://de.wikipedia.org/wiki/D-A-CH"
//             },
//             "hasOfferCatalog": {
//                 "@type": "OfferCatalog",
//                 "name": "SEO Dienstleistungen",
//                 "itemListElement": [
//                     {
//                         "@type": "OfferCatalog",
//                         "name": "Suchmaschinenoptimierung",
//                         "itemListElement": [
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "SEO Analyse"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "Keyword Analyse"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "SEO Texte"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "Onpage SEO"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "Offpage SEO"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "Linkaufbau"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "Technische Suchmaschinenoptimierung"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "SEO Audit"
//                                 }
//                             },
//                             {
//                                 "@type": "Offer",
//                                 "itemOffered": {
//                                     "@type": "Service",
//                                     "name": "Internationales SEO"
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         },
//         {
//             "@type": "FAQPage",
//             "id": "https://bluerivermountains.com/de/seo-freelancer",
//             "mainEntity": [
//                 {
//                     "@type": "Question",
//                     "name": "Wie findet man einen guten SEO Experten online?",
//                     "acceptedAnswer": {
//                         "@type": "Answer",
//                         "text": "Mit einer einfachen Google-Suche solltest Du eine handvoll professionelle SEO Freelancer finden. Sie scheinen erfolgreiche Suchmaschinenoptimierung zu betreiben, da Du sie direkt finden konntest. Das heißt, sie scheinen etwas von SEO zu verstehen, da Suchbegriffe im SEO-Bereich besonders hart umkämpft sind. Ein guter SEO-Freelancer kann dir anonymisiert Erfolge von vorherigen Projekten zeigen und genau erklären, welche Herausforderung es bei Dir zu bewältigen gibt. Davon ausgehend kann er Dir nötige Korrekturen schildern und einen realistischen Zeitrahmen für die Erfüllung nennen. Wenn die Angebote außerdem eine transparente Arbeitsweise mit einer flexiblen Vertragsbindung enthalten, kannst Du nicht viel falsch machen. Sei vorsichtig mit Versprechungen wie “Platz 1 in x Monaten”, da solche Aussagen eher von Verkäufern als von einem SEO Spezialisten getroffen werden."
//                     }
//                 },
//                 {
//                     "@type": "Question",
//                     "name": "Was kostet ein SEO Freelancer?",
//                     "acceptedAnswer": {
//                         "@type": "Answer",
//                         "text": "Mein Stundensatz liegt bei 120 € netto pro Stunde. Eine monatliche Betreuung kostet ab 3000 € netto für ein SEO Paket. SEO Freelancer sind grundsätzlich günstiger als Agenturen, da sie Verwaltungs- und Mietkosten sparen. Da ein Marketing Freelancer jedoch nur eine begrenzte Anzahl an Kunden betreuen kann, ist auch ein höherer Stundenlohn als bei einer SEO Firma möglich. Der Preis reflektiert generell Qualität, das Ausmaß der nötigen Maßnahmen und den Zeithorizont des Kunden."
//                     }
//                 },
//                 {
//                     "@type": "Question",
//                     "name": "Warum einen Freelancer beauftragen anstatt einer SEO Agentur?",
//                     "acceptedAnswer": {
//                         "@type": "Answer",
//                         "text": "Man bekommt mit einem selbstständigen SEO Berater mehr Expertise und Leistung für sein Geld. Agenturen sind durch die verbundenen Kosten für Personal und Miete teurer. Außerdem können sie leistungsfähige SEO Experten nicht lange halten, da sie schließlich direkt bei Kunden oder selbstständig arbeiten können. Das heißt, man bekommt mehr Know-how, Qualität und ausgeführte Korrekturen beim Freiberufler. Eine Agentur liefert bestenfalls den Status quo. Andere Gründe sind flexiblere Verträge und außerdem der direkte, persönliche Kontakt."
//                     }
//                 },
//                 {
//                     "@type": "Question",
//                     "name": "Was macht einen guten Freelancer für Suchmaschinenoptimierung aus?",
//                     "acceptedAnswer": {
//                         "@type": "Answer",
//                         "text": "Ein guter Freelancer passt auf persönlicher Ebene zum Unternehmen und ist ein technisches Talent. Neben Motivation sind Transparenz über Risiken und Offenheit, wann andere Internet-Marketing Kanäle (z. B. Paid Search) besser die Ziele erreichen, wichtige persönliche Merkmale. Fachlich sollte der Freelancer sich mit den Technologien der Webseite gründlich auskennen und einen individuellen Plan haben, der auf die Konkurrenten und die Ansprüche des potenziellen neuen Kunden ausgelegt ist. Es sollte ein systematischer Prozess erkennbar sein, der der Webseite einen stetigen Anstieg in Sichtbarkeit verspricht. Die Spreu vom Weizen trennt dabei, wer die Kosten für Texterstellung und White-Hat, Black-Hat & Grey-Hat Methoden fundiert abschätzen entsprechend beraten kann."
//                     }
//                 },
//                 {
//                     "@type": "Question",
//                     "name": "Wie lange dauert SEO?",
//                     "acceptedAnswer": {
//                         "@type": "Answer",
//                         "text": "Du kannst schon innerhalb von 4 Wochen Resultate sehen, vorausgesetzt die Änderungen der SEO-Maßnahmen wurden indexiert. Jedoch ist es schwierig, absolut zu sagen, wie lange SEO dauert, da Faktoren wie das Suchwort, Konkurrenten und das Alter der Domain eine wichtige Rolle spielen. Ahrefs hat zum Beispiel in einer Untersuchung herausgefunden, dass Google nur Websites, die mindestens 1 Jahr alt sind, auf Seite 1 zeigt. Trotzdem: Nach ein paar Wochen sollte auf jeden Fall mehr Seitenaufrufe zu sehen sein."
//                     }
//                 }
//             ]
//         },
//         {
//             "@type": "WebPage",
//             "headline": "SEO Freelancer gesucht?",
//             "id": "https://bluerivermountains.com/de/seo-freelancer",
//             "url": "https://bluerivermountains.com/de/seo-freelancer",
//             "about": [
//                 {
//                     "@type": "thing",
//                     "name": "SEO",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschinenoptimierung",
//                         "https://www.google.de/search?q=seo&kgmid=/m/019qb_"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Freelancer",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Freier_Mitarbeiter",
//                         "https://www.google.de/search?q=freelancer&kgmid=/m/02g0j0"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Suchmaschine",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschine",
//                         "https://www.google.de/search?q=suchmaschine&kgmid=/m/0dgjp2"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Google",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Google"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Kunde",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Kunde",
//                         "https://www.google.de/search?q=kunde&kgmid=/m/01j0mk"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Leistung",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Dienstleistung"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Expert",
//                     "sameAs": [
//                         "http://de.wikipedia.org/wiki/Experte",
//                         "https://www.google.de/search?q=experte&kgmid=/m/02pkl"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Suchmaschinenoptimierung",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschinenoptimierung",
//                         "https://www.google.de/search?q=suchmaschinenoptimierung&kgmid=/m/019qb_"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Backlink",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/R%C3%BCckverweis",
//                         "https://www.google.de/search?q=backlinks&kgmid=/m/02s3gg"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Digitales Marketing",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Online-PR#Digitales_Marketing",
//                         "https://www.google.de/search?q=digitales+marketing&kgmid=/m/0nb7jhq"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Tool",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Dienstprogramm",
//                         "https://www.google.de/search?q=Dienstprogram&kgmid=/m/03nhvb"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Strategie",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Strategie_(Wirtschaft)",
//                         "https://www.google.de/search?q=strategie&kgmid=/m/07b7r"
//                     ]
//                 }
//             ],
//             "mentions": [
//                 {
//                     "@type": "thing",
//                     "name": "Suchmaschinenoptimierung",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschinenoptimierung",
//                         "https://www.google.de/search?q=suchmaschinenoptimierung&kgmid=/m/019qb_"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Suchmaschinenmarketing",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschinenmarketing",
//                         "https://www.google.de/search?q=Suchmaschinenmarketing&kgmid=/m/06mw8r"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "SEOs",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschinenoptimierung",
//                         "https://www.google.de/search?q=seos&kgmid=/m/019qb_"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Online Marketer",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Online-Marketing",
//                         "https://www.google.de/search?q=Online+Marketer&kgmid=/m/05b1rx_"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Online Marketing",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Online-Marketing",
//                         "https://www.google.de/search?q=Online+Marketer&kgmid=/m/05b1rx_"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "SEO Optimierung",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschinenoptimierung",
//                         "https://www.google.de/search?q=seos&kgmid=/m/019qb_"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Backlinks",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/R%C3%BCckverweis",
//                         "https://www.google.de/search?q=backlinks&kgmid=/m/02s3gg"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Online Marketing",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Online-Marketing",
//                         "https://www.google.de/search?q=online+marketing&kgmid=/m/05b1rx"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Google Analytics",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Google_Analytics",
//                         "https://www.google.de/search?q=google+analytics&kgmid=/m/07gnl6"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Keyword Research",
//                     "sameAs": [
//                         "http://en.wikipedia.org/wiki/Keyword_research",
//                         "https://www.google.de/search?q=keyword+research&kgmid=/m/02wvj87"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Ranking",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/Suchmaschinenranking",
//                         "https://www.google.de/search?q=serp"
//                     ]
//                 },
//                 {
//                     "@type": "thing",
//                     "name": "Dach-Region",
//                     "sameAs": [
//                         "https://de.wikipedia.org/wiki/D-A-CH",
//                         "https://www.google.de/search?q=Dach+Region&kgmid=/m/011725cw"
//                     ]
//                 }
//             ]
//         }
//     ]
// }
// `

const SeoFreelancer = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>

        <React.Fragment>
            <SEO
                title="SEO Freelancer Profil: M. Kupperschmidt"
                description={`Profil von Matthias Kupperschmidt, freiberuflicher SEO Spezialist mit ${experienceYears} Jahren Erfahrung. Siehe Beispiele, Leistungsumfang, Kosten und direkt hier!`}
                lang="de"
                canonical="/de/seo-freelancer"
                image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                alternateLangs={alternateLangs}
            />
            <MainContent article>
                <Img
                    src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                    alt="Matthias Kupperschmidt präsentiert beim SEO Meetup Kopenhagen, October 2019"
                />
                <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                <H as="h1">SEO Freelancer gesucht?</H>
                <ProfileCard
                    tags={["SEO Texte", "Backlinks", "Technisches SEO", "SEO Audit", "Onpage", "Offpage", "DE, EN, DA"]}
                    profession="SEO Freelancer"
                    rate={`ab ${monthlySeo}€/mtl.`}
                    location="Berlin, Deutschland / remote"
                    cta="Kontakt"
                />
                <p>Meine internen Prozesse sind so optimiert, dass ich als SEO Freelancer die selben <b><Link to="/de/seo-freelancer#meine-leistungen-f">Leistungen</Link> wie eine SEO Agentur</b> anbieten kann.</p>
                <p>Zu meinen Kunden zählen Online-Shops, Consumer-Marken, aber auch Versicherungen und SaaS Unternehmen aus dem B2B Bereich, die in Deutschland oder international mehr Kunden von Suchmaschinen gewinnen möchten.</p>
                <p>Meine <Link to="/de/seo-freelancer#meine-effektive-seo-strategie">SEO Strategie</Link> fokussiert auf weitausgelegte Kampagnen mit Content-Marketing, sowie Linkbuilding.</p>
                <p>Bei einer Zusammenarbeit bin ich komplett <b>transparent</b> und biete meinen Kunden Dashboards mit Daten in Echtzeit. Sie stellen den übergeordneten Verlauf des SEO Projektes, sowie das Ranking der einzelnen Suchwörter zur Kontrolle dar.</p>
                <p><b>Meine Persönlichkeit</b> ist ehrlich, direkt und transparent (<Link to="/de/ueber-mich">mehr über mich</Link>). Lies hier die <a target="_blank" noopener="true" href="https://www.trustpilot.de/review/bluerivermountains.com">Bewertungen</a> meiner Kunden oder erfahre mehr über meine Laufbahn auf{" "}
                    <a rel="noopener" target="_blank" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                        Linkedin
                    </a>{" "}
                    .
                </p>

                <p>Angebote werden basierend auf der Situation der Webseite, der Konkurrenz und der Ambitionen individuell abgestimmt.</p>
                <br />
                <br />
                <H as="h6" style={{ "textAlign": "center" }}>Bisherige Kunden</H>
                <ImgScreenshot
                    src="about/clients_700px.png"
                    alt="clients I worked with"
                    className="article-img"
                />
                <br />
                <br />
                <br />
                <H as="h2" style={{ "textAlign": "center" }}>Angebot anfordern</H>
                <ContactForm showHeadline={false} formName="ga consultant (DE) - contact form" />



                <H as="h2">Mehr Kunden &amp; mehr Umsatz durch bessere Google-Rankings</H>
                <p>Zur Veranschaulichung hier der Verlauf von Seitenaufrufen von Besuchern durch organischen Traffic für einige meiner SEO Projekte. Ich habe mit Absicht Traffic-Verläufe mit starker Beeinflussung durch die Jahreszeit oder COVID19 gewählt, um der Realität treu zu bleiben.</p>
                <H as="h3">SEO Projekt mit monatlicher Optimierung für 6 Monate</H>
                <ImgScreenshot
                    src="seo-freelancer/first-project-raw.png"
                    alt="Projekt von 2020"
                    caption={`Die Webseite liegt in einem wetterabhängigem Markt. 80% des Umsatzes geschehen im Sommer. 1. Keyword-Recherche & Onpage-Maßnahmen (US, GB, AUS, DE, DA, CH-DE und AT-DE) 2. Neuer Content, Onpage Verbesserung und Beseitigung technischer Probleme 3. Säuberung des Linkprofils & Outreach. Bei ersten Wetterhochs in Europa kommen die Besucher. Unter COVID19 gehen Suchen dann zurück. 4. Outreach. Mit sich verbesserndem Wetter im April und weniger Besorgnis über COVID19 im Mai hat die Webseite binnen 7 Monaten täglich ca. 4mal mehr organische Besucher.`}
                    className="article-img"
                />

                <H as="h3">9 Monate SEO Optimierung</H>

                <ImgScreenshot
                    src="seo-freelancer/second-project-raw.png"
                    alt="Projekt von 2020"
                    caption={`1. Keyword-Analyse für DE, AT-DE, CH-DE, CH-EN, DA, EN, IT, PL und HU. 2. Onpage-Korrekturen und Schema-Markup. 3. Featured-Snippets. Schon 3mal mehr Besucher pro Tag. 4. COVID19-Beunruhigung lässt die organischen Seitenaufrufe zurückfallen. Rankings bleiben. 5. Beunruhigung verbessert sich. Resultat: ca. 2.5mal mehr Besucher täglich.`}
                    className="article-img"
                />

                <H as="h3">SEO-Paket mit 8-monatiger Optimierung</H>

                <ImgScreenshot
                    src="seo-freelancer/third-project-raw.png"
                    alt="Projekt von 2020"
                    caption={`1. Keyword-Research für DE, US-EN, CA-EN, GB-EN, IN-EN, IE-EN und DA. 2. On-Page und Schema Markup. 3. Lösung von Problemen mit hreflang-Anmerkungen für internationales SEO. 4. Outreach Linkbuilding. 5. COVID19 lässt Suchen fallen, Rankings bleiben bestehen. 6. Ca. 250% mehr organische Besucher am Tag.`}
                    className="article-img"
                />





                <H as="h2" style={{ "textAlign": "center" }}>Angebot erfragen</H>
                <ContactForm showHeadline={false} formName="seo freelancer (DE) - contact form" />

                <H as="h2">Meine Leistungen für Suchmaschinen&shy;optimierung</H>

                <FeatureBox
                    type="text"
                    alt="Internetinhalte optimiert für Google Rankings"
                    headline="SEO Texte"
                    h="h3"
                >Webinhalte optimiert für Suchmaschinen. SEO Content geschrieben mit den modernsten Technologien von professionellen Copywritern mit Marktkenntnissen. Keine Keywordstopferei.</FeatureBox>

                <FeatureBox
                    type="links"
                    alt="Linkaufbau"
                    headline="Offpage SEO (Backlinks)"
                    h="h3"
                >Beim Linkbuilding liegt der Fokus auf Relevanz. Nur Backlinks von nischen-relevanten Artikeln auf echten Webseiten.</FeatureBox>

                <FeatureBox
                    type="check"
                    alt="SEO-Check für Onpage Faktoren"
                    headline="Onpage & On-Site"
                    h="h3"
                >Optimierung von Meta Daten, interner Verlinkungen und der wichtigen SEO Faktoren auf die passenden Suchbegriffe.</FeatureBox>

                <FeatureBox
                    type="tech"
                    alt="technische Suchmaschinenoptimierung"
                    headline="Technisches SEO"
                    h="h3"
                >Technische Optimierung als Dienstleistung umfasst z.B. Geschwindigkeits&shy;optimierung, hreflang-Anmerkungen, Accelerated Mobile Pages und Lösungen mit JavaScript oder Python. Alles direkt aus erster Hand.</FeatureBox>

                <FeatureBox
                    type="search"
                    alt="SEO Analysen"
                    headline="SEO Analyse"
                    h="h3"
                >Detaillierte SEO Audits, Konkurrenzanalyse sowie tiefgründige Keyword-Analysen mit den besten SEO Tools auf dem Markt.</FeatureBox>



                <H as="h2">Kosten für Freelancer SEO Service</H>
                <p>Der Stundensatz für meine freiberufliche SEO-Beratung beträgt <b>{hourlyRate} €</b> netto pro Stunde. Eine laufende monatliche SEO Optimierung kostet ab <b>{monthlySeo} €</b> netto im Monat.</p>

                <QuoteBox
                    quote={`Die Kosten für ein Freelancer SEO-Projekt hängen von deinen Zielen, Zeitrahmen, der aktuellen Website-Optimierung und deinem Wettbewerb ab.`}
                />
                <p><b>Einflussfaktoren auf den Preis</b> sortiert nach Stärke:</p>
                <ul>
                    <li>Alter und Autorität der Webseite</li>
                    <li>Wettbewerbsfähigkeit der Nische</li>
                    <li>Bedarf an Projektmanagement (Meetings &amp; E-Mails)</li>
                    <li>Umfang der Optimierungen</li>
                    <li>Zeitplanung</li>
                    <li>Schnelligkeit des Kunden intern Entscheidungen zu treffen</li>
                    <li>Benötigte Strategie für Linkbuilding</li>
                </ul>
                <p>Um also ein Angebot zu bestimmen, wird der Vertragsumfang zu Beginn des Projektes auf der Grundlage der oben genannten Punkte von mir abgeschätzt.</p>
                <H as="h3">Monatliche SEO Betreuung</H>
                <p>Meine Preise für SEO-Pakete beginnen ab <b>{monthlySeo} €</b> netto pro Monat und basieren auf dem Umfang des Freelancer Projektes.</p>
                <p>Wie oben aufgelistet gibt es viele Einflussfaktoren, d.h. standard Preise würden den Bedarf an Änderungen selten vernünftig decken. Daher plane ich für jedes SEO-Freelancer Projekt erst den Projektumfang.</p>
                <p>Für monatliche, fortlaufende SEO-Optimierung wird der Projektumfang der Beratung durch Aufgaben und Meilensteine definiert.</p>
                <p>Basierend auf dem Stundenlohn, den geplanten SEO-Maßnahmen und der erforderlichen Arbeitszeit sind die <Link to="/de/was-kostet-seo">SEO Kosten</Link> somit planbar und können für jeden Monat mit dem selben Betrag begrenzt werden.</p>
                <p>Ich liefere meine Dienstleistungen ausschließlich <b>remote</b> aus dem Homeoffice. <b>Englische Webseiten</b>, die <b>DACH-Region</b> und <b>Dänemark</b> sind meine Kerngebiete, das heißt <b>internationales SEO</b> ist Routine.</p>
                <p>Sobald ich Zugriff auf die Website und die zugehörigen Analytics Tools, wie Google Search Console und Google
                    Analytics habe, kann ich mit der Arbeit beginnen.</p>

                <H as="h2">Wie ich als Freelancer arbeite (Vorteile im Überblick)</H>

                <FeatureBox
                    type="report"
                    alt="SEO Reporting mit Dashboard"
                    headline="Live Reports"
                    h="h3"
                >Du erhältst Reporting durch ein Echtzeit Dashboard mit detaillierten Informationen zum Ranking der Keywörter aus den
                    organischen Suchergebnissen. Die Suchergebnisse gehen dabei laufend 90 Tage zurück.</FeatureBox>

                <FeatureBox
                    type="plan"
                    alt=""
                    headline="Individuelle Bedürfnisermittlung"
                    h="h3"
                >Meine Plan wird genau auf die Konkurrenten, die Ambitionen und unter Berücksichtigung technischer Herausforderungen
                    aufgestellt. Keine Abfertigung wie am Fließband.</FeatureBox>


                <FeatureBox
                    type="contact"
                    alt="persönliche SEO Betreuung"
                    headline="Persönlicher Kontakt"
                    h="h3"
                >Ich halte Emails kurz und prägnant. Ich nehme gerne Videos auf, um komplizierte Sachverhalte zu erklären. Vor allem,
                    wenn beide den Bildschirm sehen sollten. Außerdem bin ich immer bereit für eine persönliche Beratung via Videocall.</FeatureBox>


                <FeatureBox
                    type="learn"
                    alt=""
                    headline="Freelancer SEO Beratung mit Kompetenz"
                    h="h3"
                >Ich kenne sowohl <b>Blackhat</b> als auch <b>Whitehat</b> Methoden. In welcher Gruppe man Maßnahmen einsortiert ist irrelevant. Es gilt
                    Risiken und Kosten für die Zielerreichung einzuschätzen. Unter Absprache wird letztlich der optimale Ansatz gewählt. Meistens bedeutet das pures Whitehat SEO mit gelegentlich Greyhat dabei.</FeatureBox>


                <H as="h2">SEO vom Freelancer mit {experienceYears} Jahren Erfahrung</H>
                <p>Ich habe seit 2011 in digitalen Marketing und Werbeagenturen (WPP, Omnicom) gearbeitet und für Kunden die Produktion
                    und Performance-Optimierung von Bannerkampagnen und Landingpages gesteuert.</p>
                <H as="h3">SEO Berater in der Agentur</H>
                <p>Seit 2014 habe ich mein Fokus im Online Marketing von Werbung und Paid Media auf organischen Traffic verschoben und
                    mich ausschließlich auf Klicks durch Suchmaschinenoptimierung fokussiert.</p>
                <p>Durch die SEO Beratungen in der Agentur hatte ich die Gelegenheit ständig mit großen Marken, börsennotierten
                    Unternehmen oder Onlineshops zu arbeiten.</p>
                <p>Ich habe für sie systematisch Links gebaut, SEO Texte erstellt und langfristige Pläne zum Erfolg geführt. Somit habe ich zigmal erlebt, wie Seiten in den Google-Rankings auf die Top-Platzierung steigen.</p>
                <p>Durch diese <b>Vielfalt an SEO Erfahrung</b> weiß ich, wie der Prozess abzulaufen hat und welchen
                    Aufwand wir für mehr Sichtbarkeit bei Google betreiben müssen.</p>

                <H as="h3">Freelancer SEO-Betreuung als Selbstständiger</H>
                <p>Seit 2018 arbeite ich als selbstständiger SEO-Freelancer und Google Analytics Consultant.</p>
                <p>Ich halte mich mit den neuesten Trends für SEO Optimierung auf dem Laufenden und baue selber mehrere Websites
                    jährlich. Ich kenne den kompletten Ablauf von Web-Projekten aus dem Effeff: Von der Planung mit Wireframes, über
                    Design, Entwicklung und schließlich bis zum Website-Launch.</p>
                <p>Dieses Know-how erlaubt mir nun als SEO-Freelancer meinen Kunden fachübergreifend mit meiner kompletten Expertise zur Seite zu stehen, wie es bei einem SEO Unternehmen nicht möglich wäre.</p>
                <p>Durch meine langjährige Praxiserfahrung als Online Marketer kann ich außerdem fundiert darüber beraten, wie man die bessere Sichtbarkeit in der Suchmaschine <b>auf anderen Marketingkanälen ausnutzt</b>, um wiederum höhere Konvertierungsraten zu erzielen.</p>
                <p><b>Retargeting</b> mit anderen Medien im <i>Einklang</i> mit Suchmaschinenmarketing ist also ein anderer Teil meiner Expertise.</p>
                <p>Ich führe den größten Teil meiner Bearbeitung selber aus und bin daher mit den Details der Umsetzung vertraut. Fallen
                    Aufgaben an, die nicht in meinen Kenntnisbereich fallen, habe ich Zugriff auf ein Netzwerk von Freelancern mit
                    Erfahrung, denen ich vertraue.</p>
                <p>Meine Kundenerfahrung im Bereich SEO erstreckt sich über unterschiedliche Branchen, von kleinen Unternehmen zu bürokratischen Konzernen und Webseiten in mehreren Sprachen. SEO Hilfe leiste ich manchmal auch durchaus als Freelancer für Agenturen. Ich kann mich also problemlos der Unternehmenskultur anpassen.</p>
                <H as="h3">Was viele bei der Bewertung von SEO Experten nicht verstehen</H>
                <p>In der Suchmaschinenoptimierung ist eine langjährige Erfahrung nicht unbedingt eine Garantie für Qualität, da sich
                    Webtechnologien und Algorithmen der Suchmaschinen regelmäßig ändern. Das heißt, was zum Beispiel 2015 noch als
                    angesehende Methode galt, wird heutzutage teils schon verpönt.</p>
                <QuoteBox
                    quote={`Viele der alten SEOs halten sich nicht auf dem Laufenden, so dass ihre Fachkunde teils auf alten Methoden beruht - also riskante Maßnahmen aus heutiger Sicht.`}
                />

                <p>Natürlich setzt professionelle Suchmaschinenoptimierung einen Erfahrungsschatz mit Projekten voraus, doch ob 5 oder 10 Jahre einen Unterschied
                    machen, kommt auf das Profil und nicht auf die Zahlen an.</p>
                <p>Die SEO Branche wird von jungen Experten dominiert, da sie mit den Fortschritten mithalten können und die technischen
                    Grundlagen verstehen - klassische Tech-Branche. In Unternehmen leiten die älteren SEOs gewöhnlich das Team oder den
                    Verkauf, haben aber mit der täglichen Arbeit wenig zu tun.</p><p>Die Anzahl an Arbeitsjahren in der Branche ist also ein
                        schlechtes Indiz für Qualität, da man schnell den Anschluss verlieren kann. Beim Thema SEO ist die Frage daher nicht,
                        ob jung oder alt, sondern wie die SEO Erfolge der jüngsten <Link to="/de/seo-freelancer#mehr-kunden-">Projekte</Link> aussehen.</p>
                <p>Potenzielle Kunden sollten sich also eher für Case-Studies und die Strategien interessieren, anstatt für Lebensläufe.</p>

                <H as="h3">Dein flexibler Berater für Online-Marketing</H>
                <p>Übergreifend zum Suchmaschinenmarketing kann ich zusätzlich mit Google Analytics und Google Tag Manager behilflich
                    sein, sowie alle damit zusammenhängenden Aufgaben der Datenintegration mit Werbediensten.</p>
                <p>Ich kenne mich außerdem
                    mit HTML, CSS, JavaScript (Vanilla JS, React JS, Node JS), Python Programmierung und generell Software Architektur aus.</p>
                <p>Somit hast du nicht nur einen Online-Marketing Experten parat, sondern auch einen festen Ansprechpartner für technische Fragen.</p>



                <H as="h2">SEO Consulting vom Freelancer – Vorteile für Unternehmen</H>
                <p>SEO-Freiberufler eignen sich in der Regel gut für Kunden, die wissen, was sie wollen.</p>
                <p>Normalerweise ist dies ein Unternehmen, das zuvor mit SEO gearbeitet hat und eine erfahrene Person Inhouse für Online-Marketing hat. Solche Kunden verstehen SEO und müssen weniger an der Hand gehalten werden.</p>
                <p>Sie suchen eher nach einem zuverlässigen SEO Dienstleister als nach neuen Sparringspartnern. Sie wollen, dass die Arbeit mit einem hohen Maß an
                    Verlässlichkeit und Effektivität erledigt wird - ohne dass zu viele Powerpoints und Verkaufstaktiken auf sie zukommen.</p>
                <p>Freiberufler liefern SEO Dienstleistungen genau auf diese Art. Sie spezialisieren sich auf die Ausführung von
                    SEO-Maßnahmen mit dem größten Effekt im kürzesten Zeitraum. Für das Anstellen eines SEO Freelancers spricht zusätzlich, dass sie leidenschaftlich tätig sind.</p>



                <Link
                    to="/de/kontakt">
                    <CtaPrimary color="red" arrow="false" align="center">Angebot erfragen</CtaPrimary>
                </Link>

                <H as="h3">Meine effektive SEO Strategie</H>
                <p>Meine persönliche Vorgehensweise ist das Resultat von mühseligem Testen und Erfahrungen in vielen Branchen. Onpage-Optimierung für Ecommerce Produkte verläuft zum Beispiel mehr Konkurrenz orientiert als die Optimierung von
                    informativen Inhalten.</p>
                <p>Affiliate SEO, sowie E-Commerce SEO erfordern wiederum einen starken Fokus auf Design und Engagement anstatt auf Seitenaufrufe alleine. Beim Start-up, das auf eine baldige Unternehmensübernahme abzielt, fokussiert ein Unternehmer noch einmal auf komplett andere Kennzahlen als z.B. ein Onlineshop.</p>
                <p>Das heißt, die unterschiedlichen Unternehmensstrategien, Kostenstrukturen und Monetarisierungsmöglichkeiten beeinflussen den Optimierungsansatz imens.</p>
                <p>SEOs reden viel von <i>langfristigen</i> Strategien. Abhängig vom Wettbewerb in der Nische können aber auch aggressive kurzfristige Strategien erfolgreich ausgeführt werden. SEO kann langsam oder schnell umgesetzt werden. Die Konkurrenzanalyse, Kundenansprüche und Budget sind letztlich die ausschlaggebenden Kriterien.</p>
                <p>Meine Strategie kennzeichnet, dass ich einschätzen kann, welche SEO Faktoren in verschiedenen Nischen eine höhere
                    Priorität haben als andere. Meine individuelle SEO Analyse ermöglicht mir zusätzlich die Schwächen meiner Kokurrenten auszunutzen.</p>
                <p>Die Leistung der einzelnen SEO-Maßnahmen erfolgt dabei systematisiert und bei jeder Homepage auf die
                    gleiche Weise. Insofern die Strategie klar ist, kann sich danach über das Tempo der Ausführung geeinigt werden.</p>
                <p>Bei meinem Angebot plane ich aggressiv, um den Mehrwert sicherzustellen. Wenn also Probleme auftauchen, sollte schon
                    die Hälfte der Maßnahmen die Position bei einer Google-Suche deutlich verbessern.</p>
                <H as="h2">Vorteile eines freiberuflichen
                    SEO-Spezialisten gegenüber einer SEO Agentur</H>
                <p>Die Vorteilhaftigkeit eines SEO-Freelancers ergibt sich wie folgt:</p>
                <ul>
                    <li><strong>Besserer Preis und Leistung</strong><br />Es wird mehr Zeit für die Umsetzung und weniger Zeit für Projektmanagement und Präsentationen gebraucht. Das heißt Du bekommst viel mehr Effekt für deine Investition.</li>
                    <li><strong>Flexibilität</strong><br />Die Zusammenarbeit kann laufend beidseitig beendet werden, so dass Unternehmen ihr Marketingbudget flexibel anpassen können.</li>
                    <li><strong>Mehr Erfahrung</strong><br />Erfolgreiche Suchmaschinenoptimierer sind schwer zu finden und selten unter angestellten Mitarbeitern. SEO Freelancer sind die effektivsten SEO Experten.</li>
                    <li><strong>Speziellere SEO Profile verfügbar</strong><br />Wie in jedem Markt bringt eine fortschreitende Reife auch mehr Spezialisierung mit sich. Wähle zwischen SEO Freelancern die sich auf einen Schwerpunkt wie lokale Suchmaschinenoptimierung, Google My Business oder spezielle Nischen spezialisieren.</li>
                    <li><strong>Kontakt direkt mit dem SEO-Spezialist</strong><br />Bei direkter Kommunikation mit dem Experten gehen keine Infos verloren, so dass sich die Person inklusive der Leistung besser beurteilen lässt.</li>
                </ul>
                <p><b>Bei der Suche nach einem Suchmaschinenoptimierer würde ich daher immer einen Freelancer für die Beratung bevorzugen.</b> Sie machen sich täglich die Hände schmutzig und sammeln in kurzer Zeit mehr Erfahrung, als die Anstellung in einer Agentur ermöglichen würde.</p>
                <p>Dieses Fachwissen macht den Unterschied zwischen Top Ergebnissen und Seite 2. Wer
                    Platzierungen priorisiert, ist mit einem SEO-Freelancer daher besser aufgehoben.</p>
                <H as="h2">Nachhaltige Sichtbarkeit und mehr Reichweite bei Google
                </H>
                <p>Um die organische Sichtbarkeit bei Google zu verbessern, können gezielte Optimierungen die Reichweite eines
                    Unternehmens steigern. Es gibt unterschiedliche Ansätze, bei denen bei der Webseite die Informationsarchitektur oder die Webseiteninhalte
                    verbessert werden. Top Rankings können jedoch auch nachhaltig durch Offpage Optimierung auf anderen Webseiten (externen
                    Domains) erzielt werden.</p>
                <p>Den nachhaltigen Erfolg in den organischen Suchergebnissen erzielt man also durch eine Mischung beider Ansätze unter
                    Orientierung an der Konkurrenz.</p>
                <p>Eine anfängliche SEO Analyse ist daher für die Planung und Priorisierung der
                    wichtigsten Verbesserungen von fundamentaler Wichtigkeit. <br />Bei der Umsetzung gilt nämlich, sich auf die Verbesserungen zu konzentrieren, die am schnellsten zu mehr Reichweite
                    in den Suchmaschinen führen.</p>
                <p>Im SEO Bereich gibt es für gewöhnlich mehr Probleme auf der Website zu lösen als
                    verfügbare Zeit. Ein Experte weiß daher dank seiner Analyse, sich auf die Aufgaben mit dem größten Effekt auf die
                    Sichtbarkeit zu konzentrieren.</p>
                <H as="h2" style={{ "textAlign": "center" }}>Kontakt</H>
                <ContactForm showHeadline={false} formName="seo freelancer (DE) - contact form" />

                <H as="h2">FAQ</H>
                <H as="h3">Wie findet man einen guten SEO Experten online?</H>
                <p>Mit einer einfachen Google-Suche solltest Du eine handvoll professionelle SEO Freelancer finden. Sie scheinen erfolgreiche Suchmaschinenoptimierung zu betreiben, da Du sie direkt finden konntest. Das heißt, sie scheinen etwas von SEO zu verstehen, da Suchbegriffe im SEO-Bereich besonders hart umkämpft sind.</p>
                <p>Ein guter SEO-Freelancer kann dir anonymisiert Erfolge von vorherigen Projekten zeigen und genau erklären, welche
                    Herausforderung es bei Dir zu bewältigen gibt. Davon ausgehend kann er Dir nötige Korrekturen schildern und einen
                    realistischen Zeitrahmen für die Erfüllung nennen. Wenn die Angebote außerdem eine transparente Arbeitsweise mit
                    einer flexiblen Vertragsbindung enthalten, kannst Du nicht viel falsch machen.</p>
                <p>Sei vorsichtig mit Versprechungen wie “Platz 1 in x Monaten”, da solche Aussagen eher von Verkäufern als von einem
                    SEO Spezialisten getroffen werden.</p>
                <H as="h3">Was kostet ein SEO Freelancer?</H>
                <p>Mein Stundensatz liegt bei <b>{hourlyRate} €</b> netto pro Stunde. Eine monatliche Betreuung kostet ab <b>{monthlySeo} €</b> netto für ein SEO Paket.</p>
                <p>SEO Freelancer sind grundsätzlich günstiger als Agenturen, da sie Verwaltungs- und Mietkosten sparen. Da ein
                    Marketing Freelancer jedoch nur eine begrenzte Anzahl an Kunden betreuen kann, ist auch ein höherer Stundenlohn als
                    bei einer SEO Firma möglich. Der Preis reflektiert generell Qualität, das Ausmaß der nötigen Maßnahmen und den Zeithorizont des Kunden.</p>
                <H as="h3">Warum einen Freelancer beauftragen anstatt einer SEO Agentur?</H>
                <p>Man bekommt mit einem selbstständigen SEO Berater mehr Expertise und Leistung für sein Geld. Agenturen sind durch die
                    verbundenen Kosten für Personal und Miete teurer. Außerdem können sie leistungsfähige SEO Experten nicht lange
                    halten, da sie schließlich direkt bei Kunden oder selbstständig arbeiten können.</p>
                <p>Das heißt, man bekommt mehr Know-how, Qualität und ausgeführte Korrekturen beim Freiberufler. Eine Agentur liefert bestenfalls den Status quo.</p>
                <p>Andere Gründe sind flexiblere Verträge und außerdem der direkte, persönliche Kontakt.</p>
                <H as="h3">Was macht einen guten Freelancer für
                    Suchmaschinenoptimierung aus?</H>
                <p>Ein guter Freelancer passt auf persönlicher Ebene zum Unternehmen und ist ein technisches Talent. Neben Motivation sind Transparenz über Risiken und Offenheit, wann andere Internet-Marketing Kanäle (z.B. Paid Search) besser die Ziele erreichen, wichtige persönliche Merkmale.</p>
                <p>Fachlich sollte der Freelancer sich mit den Technologien der Webseite gründlich auskennen und einen individuellen
                    Plan haben, der auf die Konkurrenten und die Ansprüche des potenziellen neuen Kunden ausgelegt ist.</p>
                <p>Es sollte ein systematischer Prozess erkennbar sein, der der Webseite einen stetigen Anstieg in Sichtbarkeit verspricht. Die Spreu vom Weizen trennt dabei, wer die Kosten für Texterstellung und White-Hat, Black-Hat &amp; Grey-Hat Methoden fundiert abschätzen entsprechend beraten kann.</p>
                <H as="h3">Wie lange dauert SEO?</H>
                <p>Du kannst schon innerhalb von 4 Wochen Resultate sehen, vorausgesetzt die Änderungen der SEO-Maßnahmen wurden indexiert. Jedoch ist es schwierig, absolut zu sagen, wie lange SEO dauert, da Faktoren wie das Suchwort, Konkurrenten und das Alter der Domain eine wichtige Rolle spielen.</p>
                <p>Ahrefs hat zum Beispiel in einer <a rel="noopener" target="_blank" href="https://ahrefs.com/blog/how-long-does-it-take-to-rank/">Untersuchung</a> herausgefunden, dass Google nur Websites, die mindestens 1 Jahr alt sind, auf Seite 1 zeigt.</p>
                <p>Trotzdem: Nach ein paar Wochen sollten auf jeden Fall mehr Seitenaufrufe zu sehen sein.</p>
                <H as="h4" style={{ "textAlign": "center" }}>Nimm Kontakt mit mir auf und erhalte ein unverbindliches Angebot.</H>
                {/* todo, schema temporarily removed */}
                {/* <Helmet>
                    <script type="application/ld+json">{Schema}</script>
                </Helmet> */}
            </MainContent>
        </React.Fragment>

    </Layout >
);

export default SeoFreelancer;
