import React from 'react';
import styled from "styled-components";
import { UIContext } from "./UIContext";
import Twitterlogo from "../assets/icons/twitter-logo-dark.svg";

const StyledSection = styled.section`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 1fr;
padding: 2rem;
column-gap: 1rem;
/* border: 1px solid rgba(0,0,0,0.3); */
margin: 1rem 0rem 1rem 0rem;
:hover .infobox_twittershare {
    visibility: visible;
} 

.infobox_headline {
    margin: 0;
    text-align: center;
}

.infobox_twittershare {
    justify-self: end;
    visibility: hidden;
}

p.infobox_headline {
    line-height: 3rem;
    margin: 0;
    font-size: calc(18px + 1.5vmin);
    text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
    ::before{
        content: open-quote;
        font-size: calc(18px + 5vmin);
        color: var(--primary);
        margin-right: 0.5rem;
        position: relative;
        top: 0.8rem;
    } ::after{
        content: close-quote;
        font-size: calc(18px + 5vmin);
        color: var(--primary);
        margin-left: 0.5rem;
        position: relative;
        top: 1.3rem;
    }}


@media (max-width: 700px) {
    margin: 3rem 1rem 3rem 1rem;
    column-gap: 0.5rem;
}

`

// component settings


const QuoteBox = ({ quote }, location) => {

    const renderTwitterLink = (location, quote) => {
        return `https://twitter.com/intent/tweet?text=${quote} - via ${location}`
    }

    return (
        <UIContext.Consumer>
            {uicontext => (
                <a href={renderTwitterLink(uicontext.location.href, quote)} target="_blank" noopener="true">
                    <StyledSection className="infobox" >


                        <p className="infobox_headline"><b>{quote}</b></p>




                        <img src={Twitterlogo} alt={`share on twitter`} height="24px" className="infobox_twittershare" />
                    </StyledSection>
                </a>
            )}
        </UIContext.Consumer>
    );
};

export default QuoteBox;